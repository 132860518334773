<template>
  <div class="long-logo">
    <img v-if="isZhcn" src="@/assets/image/public/logo-2.png" />
    <img v-else src="@/assets/image/public/logo-3.png" />
  </div>
</template>

<script>
import { computed, toRefs, reactive } from "vue";
import { useStore } from "vuex";
import { Image } from "ant-design-vue";
export default {
  components: {
    AImage: Image,
  },
  name: "Logo",
  setup() {
    let { getters } = useStore();

    const isZhcn = computed(() => {
      return !getters.language || getters.language == "zh-CN";
    });

    return {
      isZhcn,
    };
  },
};
</script>
<style lang="less" scoped>
.long-logo {
  max-width: 80px;
  margin-right:8px;
  display:inline-block;
  img {
    max-width: 100%;
    display:inline-block;
  }
}
</style>
